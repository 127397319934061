<template>
  <v-container fluid :class="$style['container']">
    <v-overlay :value="loading || processing" :absolute="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <ToolBar :class="$style['container-header']" :title="title" />
    <div :class="$style['container-content']">
      <template v-if="loaded">
        <template v-if="error">
          <div :class="$style['container-error']">
            <p>
              <v-icon color="red darken-2">mdi-alert-circle</v-icon>
              <span>エラーが発生しました</span>
            </p>
            <v-btn @click="onBack">戻る</v-btn>
          </div>
        </template>
        <template v-else>
          <FieldGenerator
            v-for="(field, index) in fields"
            :key="index"
            :value="entity[field.name]"
            :type="field.type || 'display'"
            :name="field.name"
            :label="field.label"
            :render="field.render || undefined"
            :as="field.as"
            :option="(value) => field.option(value, entity)"
          >
            <template v-if="field.type === 'custom'" :slot="field.name">
              <slot :name="field.name" :input="entity[field.name]" />
            </template>
          </FieldGenerator>
        </template>
      </template>
    </div>
    <div v-if="loaded && !error" :class="$style['container-footer']">
      <v-toolbar>
        <slot name="actions.update">
          <v-btn class="mx-1" color="primary" @click="onEdit">
            <v-icon left>mdi-update</v-icon>変更
          </v-btn>
        </slot>
        <slot name="actions.back">
          <v-btn class="mx-1" @click="onBack">
            <v-icon left>mdi-arrow-left</v-icon>戻る
          </v-btn>
        </slot>
        <slot name="actions.remove">
          <v-btn class="mx-1" color="error" @click="onDelete">
            <v-icon left>mdi-delete</v-icon>削除
          </v-btn>
        </slot>
        <slot name="actions" />
      </v-toolbar>
    </div>
  </v-container>
</template>

<script>
import ToolBar from "@/entries/front/components/app/toolbar";
import FieldGenerator from "@/entries/front/components/field/generator";
import utility from "@/utility";

export default {
  components: {
    ToolBar,
    FieldGenerator
  },
  props: {
    title: {
      type: String,
      required: true
    },
    resourceName: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    pk: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    loading() {
      return this.$store.getters[`${this.resourceName}/entity/isLoading`];
    },
    loaded() {
      return this.$store.getters[`${this.resourceName}/entity/isLoaded`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/isProcessing`];
    },
    error() {
      return this.$store.getters[`${this.resourceName}/entity/error`];
    }
  },
  methods: {
    onEdit() {
      this.$router.push({ path: `/${this.resourceName}/${this.pk}/edit` });
    },
    onBack() {
      this.$router.push({ path: `/${this.resourceName}` });
    },
    onDelete() {
      utility
        .$confirm("削除しますか？", "確認", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true
        })
        .then(() => {
          this.$store
            .dispatch(`${this.resourceName}/entity/destroy`, {
              pk: this.pk
            })
            .then(() => {
              this.$router.push({ path: `/${this.resourceName}` });
            });
        });
    }
  },
  created() {
    this.$store.commit(`${this.resourceName}/entity/initialize`);
  },
  mounted() {
    this.$store.dispatch(`${this.resourceName}/entity/show`, {
      pk: this.pk
    });
  }
};
</script>

<style module lang="scss">
$base-header-height: 50px;
$base-footer-height: 64px;

.container.container {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;

  .container-header {
    height: $base-header-height !important;
  }

  .container-content {
    height: calc(100% - #{$base-header-height + $base-footer-height});
    padding: 30px;
    overflow-y: auto;
    position: relative;

    .container-error {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      p {
        color: #ff0000;
        i {
          margin: 0 10px 0 0;
        }
        i,
        span {
          vertical-align: middle;
        }
      }
    }
  }

  .container-footer {
    height: $base-footer-height !important;
  }
}
</style>
