<template>
  <v-toolbar
    :class="$style['toolbar']"
    :height="height"
    color="grey lighten-4"
    elevation="3"
  >
    <v-app-bar-nav-icon @click.stop="onToggleSideMenu"></v-app-bar-nav-icon>
    <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
    <v-toolbar-items>
      <slot name="left" />
    </v-toolbar-items>
    <v-spacer />
    <v-toolbar-items>
      <slot name="right" />
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    height: {
      type: Number,
      default: 50
    }
  },
  methods: {
    onToggleSideMenu() {
      this.$store.commit("toolbar/toggleSideMenu");
    }
  }
};
</script>

<style module lang="scss">
.toolbar {
  z-index: 3;
  position: relative;
}
</style>
